import React from "react";
import { styled, Tab, Tabs } from "@material-ui/core";
import { getColorByPropertyValue } from "../utils/theme";

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  minHeight: "24px !important",
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 12,
    padding: "0 6px",
    color: "rgba(0, 0, 0, 0.85)",
    height: 12,
    minHeight: "24px !important",
    borderRadius: 4,
    margin: 2,
    "&:hover": {
      color: getColorByPropertyValue("--primaryColor") || "#131c24",
      opacity: 1,
    },
    "&.MuiTabs-scrollButtons": {
      display: "block !important",
    },
    "&.Mui-selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
      background: getColorByPropertyValue("--primaryColor") || "#131c24",
    },
    "&.Mui-focusVisible": {
      backgroundColor: getColorByPropertyValue("--primaryColor") || "#131c24",
    },
  })
);

const MobileTabs = (props) => {
  const {
    disabledTab = () => false,
    tabs = [],
    value,
    onChange = () => {},
  } = props;

  return (
    <CustomTabs
      value={value}
      onChange={onChange}
      indicatorColor="secondary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
      style={{
        border: "1px solid gray",
        borderRadius: 6,
        margin: "0 4px",
        width: "fit-content",
      }}
    >
      {tabs.map((tab) => {
        const disabled = disabledTab && disabledTab(tab);
        return (
          <CustomTab
            key={tab.value}
            value={tab.value}
            label={tab.title}
            disabled={disabled}
          />
        );
      })}
    </CustomTabs>
  );
};

export default MobileTabs;
