import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import TransactionFilterContext from "../../../context/transaction-filter-context";
import { checkIsDemoClient } from "../../../utils";
import MobileTabs from "../../../components/mobile-tab";

export const transactionDateFilterTabs = [
  {
    title: "7 Days",
    value: "7 Days",
    numberOfDays: 7,
  },
  {
    title: "14 Days",
    value: "14 Days",
    numberOfDays: 14,
  },
  {
    title: "30 Days",
    value: "30 Days",
    numberOfDays: 30,
  },
  {
    title: "All",
    value: "All",
    numberOfDays: 730,
  },
  {
    title: "Custom Date Range",
    value: "Custom Date Range",
  },
];

const DateFilter = () => {
  const { selectedTab, handleTabChange } = useContext(TransactionFilterContext);
  return (
    <Grid
      justify="center"
      style={{ display: "flex", marginTop: 8, width: "100%" }}
    >
      <MobileTabs
        value={selectedTab.value}
        onChange={(_, value) => handleTabChange(value)}
        tabs={transactionDateFilterTabs}
        disabledTab={(tab) => {
          return checkIsDemoClient() && tab.title === "Custom Date Range";
        }}
      />
    </Grid>
  );
};

export default DateFilter;
